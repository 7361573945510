.footer-container{
    background-color: $mainBlack;
    padding: 10% 0;
    .footer-box{
        height:50%;
        color:white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        max-width:1200px;
        margin: 0 auto;
        @media(max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 10% 2rem 15% 3rem;
        }
        .box1{
            display:flex;
            align-items: flex-start;
            justify-content: space-between;
            width:80%;
            @media(max-width: 768px) {
                flex-direction: column;
            }
            .box{
                @media(max-width: 768px) {
                    // margin-bottom:35px;
                }
                .header{
                    font-size: 1.4rem;
                }
                .border{
                    background-color: white;
                    padding-bottom: 5px;
                    height:5px;
                    width:80%;
                    margin-bottom: 15px;
                }
                ul {
                    .contacts{
                        color:$darkerWhite;
                        transition: 0.2s all ease-out;
                        &:hover{
                            color:white;
                            cursor: pointer;
                        }
                    }
                    li{
                        margin-bottom:15px;
                        font-size:1.1rem;
                        color:$darkerWhite;
                        @media(max-width: 768px) {
                            font-size:1rem;
                        }
                        a{
                            display:flex;
                            align-items: center;
                        }
                        .img-box{
                            margin: 20px 0 0;
                            width:50px;
                            @media(max-width: 768px) {
                                margin-bottom: 40px;
                            }
                            img{
                                width:100%;
                            }
                        }
                        .icon-box{
                            height:30px;
                            width:30px;
                            margin-right:10px;
                            svg{ 
                                height:100%;
                                width:100%;
                            }
                        } 
                        a{
                            color:$darkerWhite;
                            text-decoration:none;
                            font-size:1.1rem;
                            @media(max-width: 768px) {
                                font-size:1rem;
                            }
                            &:hover{
                                color:white;
                                cursor: pointer;
                            }
                        }
                    }
                    .address2{
                        margin-bottom:0;
                    }
                }
            }
        }
        .box2{
            width:80%;
            .last{
                border-top: 1px $darkerWhite solid;
                padding-top:5px;
                margin-top:10px;
                font-size:0.9rem;
                color:$darkerWhite;
            }
            @media(max-width: 768px) {
                margin-top:30px;
                width:auto;
            }
        }
    }
}