.gallery-container {
  padding: 0 1rem;
  max-width: 1200px;
  margin: 0 auto;
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: $mainBlueDarker;
    text-align: center;
    padding: 5rem 0 3rem;
  }
  .gallery-box {
    height: 400px;
    border: none;
    transition: 0.2s all ease-out;
    width: 100%;
    .react-multi-carousel-track {
      margin-bottom: 15px;
    }
    .react-multi-carousel-item {
      width: 33%;
      .carousel-img-box {
        margin: 5px;
        @media (max-width: 768px) {
          margin: 0;
        }
        img {
          width: 100%;
        }
      }
    }
    .react-multu-carousel-dot-list {
      bottom: -10px;
      .react-multi-carousel-dot {
        button {
          border-color: $mainBlueDarker !important;
        }
      }
    }
  }
  .redirect-box {
    margin-top: 3rem;
    width: 100%;
    padding-right: 1rem;
    a {
      text-align: end;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: black;
      .icon-box {
        width: 18px;
        height: 18px;
        margin-left: 5px;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
