.warranty-container {
  background: white;
  .name {
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      align-self: start;
      font-size: 2.2rem;
    }
  }
  .warranty-form-name {
    font-weight: 700;
    font-size: 2rem;
    @media (max-width: 768px) {
      align-self: start;
      font-size: 1.6rem;
    }
  }
  .sub-section {
    font-weight: 700;
    font-size: 1.2rem;
    margin: 32px 0 16px;
    @media (max-width: 768px) {
      align-self: start;
      font-size: 1.2rem;
    }
  }
  .warranty-wrapper {
    padding: 0 16px;
    margin-top: 200px;
    margin-bottom: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      margin-top: 150px;
      margin-bottom: 5rem;
    }
    .warranty-image-wrapper {
      width: 100%;
      max-width: 952px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .spinner-wrapper {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 100%;
      }
    }
    .warranty-form-box {
      width: 50%;
      margin-top: 80px;
      @media (max-width: 768px) {
        width: 100%;
      }
      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .row-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          @media (max-width: 768px) {
            flex-wrap: wrap;
          }
          .item30 {
            width: 32%;
            @media (max-width: 768px) {
              width: 49%;
            }
          }
          .item50 {
            width: 49%;
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .initials {
          @media (max-width: 768px) {
            width: 100%;
          }
        }
        .date-row-wrapper {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .date-wrapper {
            display: flex;
            width: 49%;
            flex-direction: column;
          }
        }
        label {
          margin-bottom: 5px;
        }
        input {
          outline: 0;
          padding: 10px;
          margin-bottom: 18px;
          font-size: 16px;
          border: 1px $mainBlueDarker solid;
        }
        .input-field {
          width: 100%;
        }
        .divider {
          border-bottom: 1px solid $lightGrey;
          width: 100%;
        }
        .sub-sub-section {
          margin-top: 24px;
        }
        .two {
          margin-top: 0;
        }
        .checkbox-box {
          width: 49%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 5px;
          .box {
            margin-right: 10px;
            .rc-checkbox-checked {
              .rc-checkbox-inner {
                background: $mainBlue;
                border-color: $mainBlue;
              }
            }
            .rc-checkbox {
              height: 30px;
              width: 30px;
              .rc-checkbox-input {
                width: 100%;
                height: 100%;
                margin-bottom: 0;
              }
              .rc-checkbox-inner {
                width: 100%;
                height: 100%;
                border-radius: 0;
              }
              .rc-checkbox-inner::after {
                height: 16px;
                width: 8px;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
          }
        }
        .buttons-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 16px;
        }
        .send-button {
          padding: 10px 16px;
          width: 120px;
          background-color: $darkWhite;
          align-self: flex-start;
          font-size: 18px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 16px;
          @media (max-width: 768px) {
            margin-top: 20px;
            align-self: flex-start;
          }
          &:hover {
            cursor: pointer;
          }
          span {
            height: 24px;
            width: 24px;
            svg {
              height: 100%;
              width: 100%;
            }
          }
        }
        .active {
          background-color: $mainBlue;
          color: $mainBlack;
        }
        .clear-button {
          white-space: nowrap;
          padding: 10px 16px;
          width: 120px;
          background-color: transparent;
          align-self: flex-start;
          font-size: 18px;
          line-height: 22px;
          border: 1px solid #191919;
          color: #191919;
          margin-top: 16px;
          margin-left: 24px;
          &:hover {
            cursor: pointer;
          }
          @media (max-width: 768px) {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

// .Snackbar_snackbar-wrapper__ocbPJ {
//   z-index: 1002;
// }
