.about-container {
  background: white;
  .about-company-box {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      margin-top: 150px;
    }
    .about-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 952px;
      text-align: center;
      @media (max-width: 768px) {
        align-items: flex-start;
      }
      .name {
        font-weight: 700;
        font-size: 2.5rem;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          align-self: start;
          font-size: 2.2rem;
        }
      }
      .info {
        font-size: 1.1rem;
        line-height: 1.5;
      }
      @media (max-width: 768px) {
        width: 100%;
        padding: 0 1rem;
        text-align: justify;
      }
    }
    .images-container {
      width: 100%;
      max-width: 1100px;
      margin: 6rem auto 0;
      .img-box {
        margin: 1.5rem;
        @media (max-width: 768px) {
          margin: 1.5rem 1rem;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
