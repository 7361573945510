.modal-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1002;
  @include flexCenCen();
  .modal-box {
    height: 95%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 820px;
    @media (max-width: 820px) {
      width: 100%;
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: auto;
      @media (max-width: 820px) {
        width: 85%;
        height: auto;
      }
      img {
        width: auto;
        position: "relative";
        height: 100%;
        @media (max-width: 820px) {
          height: auto;
          width: 100%;
        }
      }
      .icon {
        position: absolute;
        right: 20px;
        top: 20px;

        @media (max-width: 820px) {
          right: 20px;
          top: 20px;
          width: 55px;
          height: 55px;
        }
        @media (max-width: 768px) {
          right: 10px;
          top: 5px;
        }
        width: 45px;
        height: 45px;
        z-index: 12;
        svg {
          width: 100%;
          height: 100%;
          color: white;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
