.banner-container {
  background-color: $mainMustard;
  position: fixed;
  width: 100%;
  top: 86px;
  z-index: 10000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  @media (max-width: 768px) {
    padding: 8px 0;
  }
  .banner-box {
    width: 100%;
    max-width: 1200px;
    position: relative;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .banner-header {
      font-size: 2.8rem;
      font-weight: 800;
      font-style: italic;
      color: $mainGrey;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 1.6rem;
      }
    }
    .banner-body {
      padding: 0px 1px;
      font-size: 1.4rem;
      font-weight: 800;
      font-style: italic;
      color: $mainGrey;
      @media (max-width: 768px) {
        font-size: 1.1rem;
        font-weight: 600;
        text-align: center;
        background-color: transparent;
      }
    }
    .close-btn {
      position: absolute;
      right: 1rem;
      top: -0.2rem;
      height: 35px;
      width: 35px;
      @media (max-width: 768px) {
        height: 30px;
        width: 30px;
        right: 0.8rem;
      }
      svg {
        color: $mainGrey;
        width: 100%;
        height: 100%;
        transition: all ease 0.2s;
        &:hover {
          color: $mainBlack;
          cursor: pointer;
        }
      }
    }
  }
}
