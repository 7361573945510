.entry-container {
  height: 100vh;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem 0;
  @include flexCenCen();
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
  .img-box {
    width: 100%;
    height: 100vh;
    background-image: url("./ecs_girl_72dpi_optimized.png");
    background-position: top;
    background-size: cover;
    position: fixed;
    overflow: hidden;
    z-index: -2;
    @media (max-width: 768px) {
      background-position: cleft;
      background-image: url("./smartphone_background_lowerresolution_xs.jpg");
    }
  }
  .info-box {
    @include flexColCenLeft();
    width: 100%;
    @media (max-width: 960px) {
      width: 100%;
    }
    .name {
      font-size: 4rem;
      font-weight: bold;
      color: white;
      color: $mainBlack;
      @media (max-width: 768px) {
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        font-size: 2.4rem;
        color: white;
      }
    }
    .sub-name {
      margin-top: 20px;
      margin-right: 7rem;
      font-size: 1.2rem;
      font-weight: 600;
      color: white;
      color: $mainBlack;
      letter-spacing: 0.5px;
      line-height: 1.4;
      @media (max-width: 768px) {
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        margin-right: 0;
        color: white;
      }
    }
    button {
      margin-top: 25px;
      background-color: $mainBlueDarker;
      font-size: 1.1rem;
      padding: 10px 20px;
      color: white;
      border: none;
      transition: 0.2s all ease-out;
      a {
        color: white;
      }
      &:hover {
        color: white;
        cursor: pointer;
        background-color: $mainBlack;
      }
    }
  }
  .right {
    width: 50%;
    @media (max-width: 768px) {
      width: 0;
    }
  }
}
