.map-container {
  background-color: $mainBlack;
  width: 100%;
  height: 300px;
  @media (max-width: 768px) {
    height: 200px;
  }
  // background-image: url('./map_clean5.png');
  background-image: url("./map_new_location_cropped.png");
  background-position: center;
  background-size: auto 100%;
  position: relative;
  overflow: hidden;
  .overlay {
    width: 100%;
    height: 300px;
    position: absolute;
    @media (max-width: 768px) {
      height: 200px;
    }
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    .img-box {
      margin-top: 24px;
      margin-left: 32px;
      width: 65px;
      height: 65px;
      img {
        width: 100%;
      }
    }
  }
  .overlay2 {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 300px;
    @media (max-width: 768px) {
      height: 200px;
    }
    transition: 0.6s all ease;
    z-index: 3;
    background-color: rgba(25, 25, 25, 0);
    color: white;
    &:hover {
      opacity: 1;
      background-color: rgba(25, 25, 25, 0.2);
      cursor: pointer;
      color: white;
    }
    .icon-box {
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 1.1rem;
      }
      .icon {
        margin-left: 10px;
        height: 20px;
        width: 20px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
