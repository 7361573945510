.nav-back {
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  height: 86px;
  z-index: 1001;
  .nav-container {
    @include flexCenSpaceBtwn();
    position: fixed;
    max-width: 1200px;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 0 1rem;
    color: black;
    z-index: 8;
    @media (max-width: 768px) {
      padding: 0 1rem;
    }
    .logo-box {
      height: auto;
      width: 110px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .burger-box {
      display: none;
      @media (max-width: 768px) {
        display: block;
        width: 40px;
        height: 40px;
        svg {
          color: $mainBlack;
          width: 100%;
          height: 100%;
          transition: all ease 0.2s;
          &:hover {
            color: $mainBlack;
            cursor: pointer;
          }
        }
      }
    }
    .links-box {
      @include flexCenCen();
      @media (max-width: 768px) {
        display: none;
      }
      .link {
        margin-left: 20px;
        font-size: 1.1rem;
        font-weight: 500;
        a {
          color: $mainBlack;
        }
      }
      .link-contact {
        background: $mainBlueDarker;
        color: white !important;
        padding: 6px 10px;
        transition: 0.2s all ease-out;
        &:hover {
          background: $mainBlack;
        }
      }
    }
  }
}
.links-box-dropdown {
  transform: translateX(100%);
  opacity: 0;
  transition: all ease 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: $mainBlack;
  padding: 1rem 3rem 0.5rem;
  color: white;
  z-index: 10001;
  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 1rem;
    left: 1rem;
    .logo-box {
      height: auto;
      width: 110px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .burger-box {
      display: block;
      width: 50px;
      height: 50px;
      margin-right: 2rem;
      margin-top: 1rem;
      svg {
        color: white;
        width: 100%;
        height: 100%;
        transition: all ease 0.2s;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .link {
    margin-bottom: 20px;
    font-size: 1.6rem;
    a {
      color: white;
      transition: all ease 0.2s;
    }
  }
}
.links-box-dropdown.open {
  transform: translateX(0);
  opacity: 1;
  .box {
    margin-top: -52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contact-us-box {
    bottom: 0;
    padding: 2rem;
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15%;
    @media (max-width: 768px) {
      margin-bottom: 100px;
    }
    .contact {
      width: 30px;
      height: 30px;
      margin: 0px 25px;
      a {
        svg {
          width: 100%;
          height: 100%;
          color: $darkerWhite;
          transition: all ease 0.2s;
          &:hover {
            color: $mainGreen;
          }
        }
      }
    }
  }
}
