.widget-container {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 5;
  @media (max-width: 768px) {
    right: 1rem;
  }
  .widget-box {
    width: 60px;
    height: 60px;
    border: 2px solid white;
    padding: 13px;
    border-radius: 50%;
    background-color: $mainBlue;
    transition: all ease 0.3s;
    @media (max-width: 768px) {
      width: 65px;
      height: 65px;
    }
    &:hover {
      box-shadow: 0 0 8px 0.1px;
      padding: 12px;
      color: white;
      cursor: pointer;
    }
    &:hover svg {
      transform: rotate(-15deg);
    }
    a {
      width: 100%;
      height: 100%;
      svg {
        color: white;
        width: 100%;
        height: 100%;
        transition: all ease-out 0.3s;
      }
    }
  }
}

.hide-me {
  display: none;
}
