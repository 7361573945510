.products-container {
  background: white;
  padding-bottom: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: $mainBlueDarker;
    text-align: center;
    padding: 6rem 0 3rem;
  }
  .box {
    width: 65%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    @media (max-width: 980px) {
      width: 90%;
      padding: 0 1rem;
    }
    @media (max-width: 768px) {
      width: 100%;
      padding: 0 1rem;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    .products-box {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        flex-direction: column;
      }
      .product-box {
        cursor: pointer;
        margin-bottom: 30px;
        width: 50%;
        padding-right: 20px;
        padding-left: 10%;
        @media (max-width: 768px) {
          width: 100%;
          padding-left: 0;
        }
        .category {
          font-size: 1.2rem;
          font-weight: bold;
          text-align: start;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          .icon-box {
            margin-left: 5px;
            height: 1.4rem;
            width: 1.4rem;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        .dropdown-box {
          .img-box {
            margin: 1rem 0;
            width: 125px;
            height: 125px;
            img {
              width: 100%;
            }
          }

          .dropdown {
            cursor: default;
            padding-left: 10px;
            margin-left: 10px;
            li {
              list-style-type: square;
              margin: 12px 0;
            }
          }
        }
      }
    }
  }
  .partners-container {
    .section-title {
      font-size: 1.2rem;
      font-weight: 800;
      color: $darkerWhite;
      text-align: center;
      padding: 9rem 0 1.5rem;
    }
    .partners-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      .img-box {
        width: 150px;
        margin: 0 2rem;
        @media (max-width: 768px) {
          margin: 0;
          padding: 0 1rem 1.5rem;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  button {
    background-color: $mainBlueDarker;
    font-size: 1.1rem;
    padding: 10px 20px;
    border: none;
    transition: 0.2s all ease-out;
    margin-top: 20px;
    a {
      color: white;
    }
    &:hover {
      background: $mainBlack;
    }
    @media (max-width: 768px) {
      padding: 10px 30px;
    }
  }
}
