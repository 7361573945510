$mainBlue: #00abc8;
$mainGreen: #3bd4ae;
$muiGreen: #388e3c;
$muiError: #f44336;
$mainBlueDarker: #003a5d;
$mainBlack: #191919;
$mainGrey: #393a3a;
$lightGrey: #e4e4e4;
$darkerWhite: rgb(162, 162, 162);
$darkWhite: rgb(222, 222, 222);
$darkWhite2: rgb(238, 238, 238);
$mainMustard: #ffdb58;
