@mixin flexCenSpaceBtwn {
    display:flex;
    align-items:center;
    justify-content: space-between;
}

// @mixin flexCenSpaceEv {
//     display:flex;
//     align-items:baseline;
//     justify-content: space-evenly;
// }

@mixin flexCenCen {
    display:flex;
    align-items:center;
    justify-content: center;
}

@mixin flexColCenCen {
    display:flex;
    flex-direction:column ;
    align-items:center;
    justify-content: center;
}

@mixin flexColCenLeft {
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
}