@import "./styles/colors";
@import "./styles/functions";
@import "./components/navbar/navbar";
@import "./components/entry/entry";
@import "./components/footer/footer";
@import "./components/section/section";
@import "./components/services/services";
@import "./components/addressModal/modal";
@import "./components/widget/widget";
@import "./components/form/form";
@import "./components/instagram/instagram";
@import "./components/map/map";
@import "./components/warranty/warranty";
@import "./components/about/about";
@import "./components/products/products";
@import "./components/gallery/gallery";
@import "react-multi-carousel/lib/styles.css";
@import "./components/banner/banner";
@import "./components/sectionLogos/section-logos";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .Snackbar_snackbar-wrapper__ocbPJ {
    z-index: 1002 !important;
  }
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100%;
}

#root {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
select:focus {
  outline: none;
}
ul {
  list-style-type: none;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

button:focus {
  outline: 0;
}

input {
  &:focus {
    outline: none !important;
  }
  border-radius: 0;
}

textarea {
  &:focus {
    outline: none !important;
  }
  border-radius: 0;
}

.background {
  background-color: white;
}
