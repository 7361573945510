.services-container{
    @include flexColCenCen();
    // background-color: lighten($mainBlue, 50%);
    max-width:1200px;
    margin: 0 auto;
    padding: 0 1rem 6rem;
    @media(max-width: 768px) {
        // width:100%;
    }
    .section-title{
        font-size: 2.5rem;
        font-weight: 800;
        color: $mainBlueDarker;
        text-align: center;
        padding:6rem 0 3rem;
    }
    .services-box{
        width:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width: 768px) {
            flex-direction: column;
        }
        .service-container{
            width:30%;
            background-color: white;
            overflow: hidden;
             @media(max-width: 768px) {
                width:100%;
                margin-bottom: 30px;
            }
            .img-box{
                width:100%;
                height:200px;
                border:none;
                transition:0.2s all ease-out;
                overflow: hidden;
                &:hover .navigation{
                    background-color: rgba(0,0,0,0.1);
                    border-radius:50%;
                    color:rgba(0,0,0,0.8);
                }
                .slider-control-centerleft{
                    left: -20px!important;
                    .navigation{
                        border:none;
                        background-color: transparent;
                        border-radius:50%;
                        width:40px;
                        height:40px;
                        font-size:40px;
                        color:rgba(0,0,0,0);
                        cursor:pointer;
                        transition:0.2s all ease-out;
                    }
                    img{
                        width:100%;
                        object-fit: cover;
                        position:top;
                        border:none;
                    }
                }
            }
            .data-box{
                margin:20px 0 25px;
                .name{
                    margin-top:10px;
                    font-size:1.4rem;
                    font-weight: 600;;
                }
                .info{
                    margin-top:10px;
                }
                button{
                    margin-top: 0.5rem;
                    background-color:white;
                    padding:5px 10px 5px 0;
                    border:none;
                    display:flex;
                    .one{
                        line-height: 30px;
                        font-size:1rem
                    }
                    .two{
                        opacity:0;
                        transition: 0.2s all ease-out;
                        width:30px;
                        height:30px;
                        transform: translateX(-5%);
                        svg{
                            width:100%;
                            height:100%;
                        }
                    }
                    &:hover{
                        cursor: pointer;
                    }
                    &:hover .two{
                        opacity:1;
                        transform: translateX(5%);
                        color: $mainBlue;
                    }
                }
            }
        }
    }
}