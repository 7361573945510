.section-logos-container {
  padding: 9rem 0;
  .section-logos-title {
    font-size: 1.2rem;
    font-weight: 800;
    color: $darkerWhite;
    text-align: center;
    padding-bottom: 1.5rem;
  }
  .section-logos-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    .img-box {
      height: 75px;
      margin: 0 2rem;
      @media (max-width: 768px) {
        margin: 0;
        padding: 0 1rem 1rem;
      }
      img {
        height: 100%;
      }
    }
  }
}
