.form-container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: $mainBlueDarker;
    text-align: center;
    padding: 5rem 0 3rem;
  }
  .form-box {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    form {
      width: 100%;
      padding: 40px 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      label {
        margin-bottom: 5px;
      }
      input {
        outline: 0;
        padding: 15px 10px;
        margin-bottom: 10px;
        font-size: 16px;
        border: 1px $mainBlueDarker solid;
      }
      .input-field {
        width: 100%;
      }
      .materials-title {
        margin-top: 10px;
      }
      .materials {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .checkbox-box {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 5px;
          .box {
            margin-right: 10px;
            .rc-checkbox-checked {
              .rc-checkbox-inner {
                background: $mainBlue;
                border-color: $mainBlue;
              }
            }
            .rc-checkbox {
              height: 30px;
              width: 30px;
              .rc-checkbox-input {
                width: 100%;
                height: 100%;
                margin-bottom: 0;
              }
              .rc-checkbox-inner {
                width: 100%;
                height: 100%;
                border-radius: 0;
              }
              .rc-checkbox-inner::after {
                height: 16px;
                width: 8px;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
          }
        }
      }
      textarea {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        font-size: 16px;
        border: 1px $mainBlueDarker solid;
      }
      button {
        padding: 10px 16px;
        width: 160px;
        background-color: $darkWhite;
        align-self: flex-start;
        font-size: 18px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        @media (max-width: 768px) {
          margin-top: 20px;
          align-self: flex-start;
        }
        &:hover {
          cursor: pointer;
        }
        span {
          height: 24px;
          width: 24px;
          svg {
            height: 100%;
            width: 100%;
          }
        }
      }
      .active {
        background-color: $mainBlue;
        color: $mainBlack;
      }
    }
  }
}
