.section-container{
    background-color: $mainBlueDarker;
    width:100%;
    @include flexColCenCen(); 
    padding: 5rem 0;
    .section-box{
        width:50%;
        @media(min-width: 1200px) {
            width:40%;
        }
        @include flexColCenCen(); 
        @media(max-width: 768px) {
            width:90%;
        }
        .text{
            font-size: 1.4rem;
            width:100%;
            color:white;
            line-height: 1.5;
            text-align: center;
            @media(max-width: 768px) {
            }
        }
        .icon{
            margin-top:25px;
            height:80px;
            width:80px;
            img{
                width:100%;
                height:100%;
            }
        }
    }
}

.section2-container{
    background-color: $darkWhite2;
    width:100%;
    @include flexColCenCen(); 
    padding: 5rem 0;
    .section-box{
        width:50%;
        @media(min-width: 1200px) {
            width:40%;
        }
        @include flexColCenCen(); 
        @media(max-width: 768px) {
            width:90%;
        }
        .text{
            font-size: 1.4rem;
            width:100%;
            color: $mainBlueDarker;
            line-height: 1.5;
            text-align: center;
            @media(max-width: 768px) {
            }
        }
        .icon{
            margin-top:25px;
            height:80px;
            width:80px;
            svg{
                width:100%;
                height:100%;
                color: $mainBlueDarker;
            }
        }
    }
}